import React, { useEffect, useState } from "react";
import { GetAllContent } from "../api/MainRequests";
import TabItem from './TabItem'
import TabContent from "./TabContent";

export default function News(){
    const [highlights, setHighlights] = useState([])
    const [contents, setContent] = useState([])
    const [activeTab, setActiveTab] = useState("tab1")
    useEffect(() => {
        GetAllContent().then(res =>{
            setHighlights(res)
        })
    },[])
    function getContent(content){
        setContent(content)
        handleTab1()
    }
    const handleTab1 = () => {
        setActiveTab("tab1")
    }
    console.log(highlights)
    function focus(){
        if(contents.length !== 0){
            return (
                <div>
                    <div className="news-focus">
                    <img src={contents.content_picture} width="100%" height="100%" className="object-fit-cover" alt="news_photo"/>
                    </div>
                    <h2 className="bolder p-4 main lh-1">{contents.title}</h2>
                    <h6>by {contents.writer}</h6>
                    <h5 className="lh-1 text-start p-3">{contents.content}</h5>
                    <h6 className="text-start mt-3 px-3">{contents.date} | PAKWACH FM</h6><div className="d-flex flex-row mt-3 mx-3">
                    <a href={contents.link}><h6 className="active bolder">{contents.link_title}</h6></a></div>
                    <div>
                    </div>
                </div>
            )
        } else if(highlights.length !== 0){
            console.log(highlights)
            return (
                <div>
                    <div className="news-focus">
                    <img src={highlights[0].content_picture} width="100%" height="100%" className="object-fit-cover" alt="news_photo"/>
                    </div>
                    <h2 className="bolder p-4 main lh-1">{highlights[0].title}</h2>
                    <h6>by {highlights[0].writer}</h6>
                    <h5 className="lh-1 text-start p-3">{highlights[0].content}</h5>
                    <h6 className="text-start mt-3 px-3">{highlights[0].date} | PAKWACH FM</h6><div className="d-flex flex-row mt-3 mx-3">
                    <a href={highlights[0].link}><h6 className="active bolder">{highlights[0].link_title}</h6></a></div>
                    <div>
                    </div>
                </div>
            )
        }
    }
    return(
        <div className="top">
            <div className="row mt-5">
            <div className="col-lg-9 col-sm-12 bg-white p-0 rounded-2">
                <div className="bg-white text-start">
                <TabContent id="tab1" activeTab={activeTab}>
                    {focus()}
                </TabContent>
                </div>
            </div>
                <div className="col-lg-3 px-lg-3 rounded text-start">
                <div className="row px-4 rounded-2">
                <h6 className="mb-3">highlights</h6>
                <div className="scroll-highlights">
                {
                    highlights.map((content, id) => (
                        <div key={id} onClick={() => getContent(content)}>
                        <TabItem id="tab2" activeTab={activeTab} setActiveTab={setActiveTab} title={<div className="tab-item">
                            <img src={content.content_picture} width="100%" alt="highlight"/>
                            <h5 className="lh-1 mt-2 d-block text-truncate" style={{width: "15rem"}}>{content.content}</h5>
                            <h6>{content.date}</h6>
                            </div>}/>
                        </div>
                    ))
                }
                    </div>
                </div></div>
            </div>
        </div>
    )
}