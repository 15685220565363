
import './App.css';
import NavBar from './pages/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './auth/Login';
import Admin from './pages/Admin';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path='/' element={<NavBar/>}>
        </Route>
        <Route path='/admin' element={<Login/>}>
        </Route>
        <Route path='/admin/web' element={<Admin/>}>
        </Route>
      </Routes>
    </Router>
    </div>
  );
}

export default App;