import axios from "axios";
import { TOKEN, API_URL_USER_GET_PROFILE_PHOTO, API_URL_GET_ALL_CONTENT, API_URL_GET_CONTENT_BY_ID, API_URL_GET_USER_VERIFICATION, API_URL_GET_AUTH_USER, API_URL_GET_ADMIN_USER, API_URL_GET_NON_ADMIN_USER, API_URL_GET_ALL_USERS } from "./Api";

export const UserRequests = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_AUTH_USER}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error) {
        return error ? error.response ? error.response.data : error : error.message;
    }
};

export const AdminUserRequests = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_ADMIN_USER}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error) {
        return error ? error.response ? error.response.data : error : error.message;
    }
};

export const NonAdminRequests = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_NON_ADMIN_USER}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error) {
        return error ? error.response ? error.response.data : error : error.message;
    }
};

export const UserVerificationRequests = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_USER_VERIFICATION}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error) {
        return error ? error.response ? error.response.data : error : error.message;
    }
};

export const ProfilePhoto = async() => {
    try {
        const response = await axios.get(`${API_URL_USER_GET_PROFILE_PHOTO}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error) {
        return error ? error.response ? error.response.data : error : error.message;
    }
};

export const GetAllUsers = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_ALL_USERS}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error){
        return error ? error.response ? error.response.data : error : error.message
    }
};

export const GetAllContent = async() => {
    let website = localStorage.getItem("web")
    if(website == "web"){
        try {
            const response = await axios.get(`${API_URL_GET_ALL_CONTENT}`, {
                headers: {
                    "Authorization": `Token 8664499d18983a72927018fe2898cfde1122ad92`
                }
            });
            return response.data;
        } catch (error){
            return error ? error.response ? error.response.data : error : error.message
        }
    } else{
        try {
            const response = await axios.get(`${API_URL_GET_ALL_CONTENT}`, {
                headers: {
                    "Authorization": `Token ${TOKEN}`
                }
            });
            return response.data;
        } catch (error){
            return error ? error.response ? error.response.data : error : error.message
        }
    }
};
export const GetContentById = async() => {
    try {
        const response = await axios.get(`${API_URL_GET_CONTENT_BY_ID}`, {
            headers: {
                "Authorization": `Token ${TOKEN}`
            }
        });
        return response.data;
    } catch (error){
        return error ? error.response ? error.response.data : error : error.message
    }
};