import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button";
import carousel from '../images/carousel.png'
import landing from "../images/landing.png"
import { API_URL_UPLOAD_CONTENT, TOKEN } from "../api/Api";
import { preloader, catch_errors, success, fail } from "../api/RequestFunctions";
import axios from "axios";
import { useForm } from "react-hook-form";

export default function AdminRes(props){
    const [formData, setFormData] = useState(
        {
            "name": "Heading1",
            "title": "",
            "link": "",
            "date": "",
            "content": "",
            "link_title": "",
            "photo": null
        }
    )
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const handleChange = (event) => {
        const name = event.target.id;
        const value = event.target.value;
        setFormData({...formData, [name]: value });
    };
    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            photo: e.target.files[0]
        })
    };
    const { handleSubmit } = useForm();
    function onSubmit(){
        preloader()
        axios.post(`${API_URL_UPLOAD_CONTENT}`, formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    "Authorization": `Token ${TOKEN}`
            }})
            .catch(function(error) {
                catch_errors(error)
            })
            .then(function(response) {
                if (!response) {
                    fail("Something went wrong...")
                } else if (response.status === 200 && response.data.success === false) {
                    fail(response.data.message)
                } else {
                    success("content uploaded", "/admin/web", "successful");
                }
            })
    }
    return(
            <div className="scroll-y d-sm-block d-lg-name d-md-none">
                <div className="mt-5 pt-3">
                    <h2 className="">Landing Page Contents: Upload</h2>
                    <Form className=" p-3 bg-white mt-3" onSubmit={handleSubmit(onSubmit)}>
                    <p className="small p-3 text-start lh-1">Please fill in all details for each content before submission. All fields required depending on the content. Be sure to check carefully before submission. Refer to the <a href="#guide">Guide</a> below for further clarity. Submissions are not reversable.</p>
                        <div className="row mt-5">
                            <div className="col">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Name </h6> </Form.Label> 
                                <Form.Select name = "name"
                                id="name"
                                required = "required"
                                onChange = { handleChange }
                                placeholder = "username@pakwachfm.com">
                                    <option value="Heading1">Heading 1</option>
                                    <option value="Heading2">Heading 2</option>
                                    <option value="Carousel">Carousel Item</option>
                                    <option value="highlights">Highlights</option>
                                </Form.Select>
                            </Form.Group>
                            </div>
                            <div className="col-lg-4">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Title </h6> </Form.Label> 
                                <Form.Control type = "text"
                                id = 'title'
                                onChange = { handleChange }
                                placeholder = "Title of your content" /> 
                            <p className="smallest my-1">Optional</p>
                            </Form.Group>
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Barline/Writer </h6> </Form.Label> 
                                <Form.Control type = "text"
                                id = 'writer'
                                onChange = { handleChange }
                                placeholder = "content creator" /> 
                            <p className="smallest my-1">mandatory</p>
                            </Form.Group>
                            </div>
                            <div className="col-lg-4">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Link Title </h6> </Form.Label> 
                                <Form.Control type = "text"
                                id = 'link_title'
                                onChange = { handleChange }
                                placeholder = "e.g EMYOOGA SACCO" /> 
                            <p className="smallest my-1 lh-1">this is what will be displayed and on click takes you to the actual link: optional</p>
                            </Form.Group>
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Actual link </h6> </Form.Label> 
                                <Form.Control type = "text"
                                id = 'link'
                                onChange = { handleChange }
                                placeholder = "e.g https://twitter.com/pakwach/emyooga" /> 
                            <p className="smallest my-1 lh-1">starts with "https://...": not optional if link title exists</p>
                            </Form.Group>
                            </div>
                            <div className="col-lg-6">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> MEDIA </h6> </Form.Label> 
                                <Form.Control type = "file"
                                id = 'photo'
                                required = "required"
                                onChange = { handleFileChange }
                                placeholder = "username@pakwachfm.com" />
                            <p className="smallest my-1 lh-1">only images in PNG, JPG, JPEG formats allowed. videos and audio not more than 10mbs: optional to carousel items</p>
                            </Form.Group>
                            </div>
                            <div className="col-lg-2">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Date </h6> </Form.Label> 
                                <Form.Control type = "text"
                                id = 'date'
                                required = "required"
                                onChange = { handleChange }
                                placeholder = "21 Feb 2020" /> 
                            <p className="smallest my-1 lh-1">to display as date of content creation</p>
                            </Form.Group>
                            </div>
                            <div className="col-lg-8">
                            <Form.Group className = "rounded-2 px-1 text-start" >
                                <Form.Label> <h6 className = 'm-0'> Content </h6> </Form.Label> 
                                <Form.Control type = "text" as="textarea" rows={4}
                                id = 'content'
                                required = "required"
                                onChange = { handleChange }
                                placeholder = "Your content typography here..." /> 
                            </Form.Group>
                            </div>
                            <Button type="submit" id="successMessage" size="sm" className="px-lg-5 px-3 mt-5 button">UPLOAD CONTENT TO WEBSITE</Button>
                            <Modal show={show} className="my-modal1">
                                <div className="p-3 text-center bg-pakwach2">
                                    <h4>CONFIRM</h4>
                                    <p className="small p-3 lh-1"> Is content you have provided correct(spell checks, grammar, file size and format, and date format) in all certainty as this content upload is irreversible? </p>
                                    <Button type="submit" size="sm" variant="success" className="px-3">YES, UPLOAD</Button>
                                    <Button onClick={handleClose} size="sm" variant="danger" className="px-3 mx-3">CANCEL UPLOAD</Button>
                                </div>
                            </Modal>
                <h6 id = "errorMessage" className = 'py-2 mx-3 my-1 rounded border border-danger text-center fade-in'
                    style = {
                    { display: 'none' }
                    }> hey </h6>
                <h6 id = "infoMessage"
                    className = 'py-2 mx-3 my-1 rounded warning-message text-center fade-in'
                    style = {
                    { display: 'none',
                        border: '1px solid black' }
                    }> hey </h6>
                        </div>
                    </Form>
                    </div>
                    <div className="m-2 p-3 bg-white" id="guide">
                        <h6>Landing page GUIDE</h6>
                        <p className="small p-3 text-start">Basically what goes where just incase of any confusion..</p>
                        <div className="row p-3 justify-content-center">
                        <img src={landing} height="100%" width="100%" alt="carousel item"/>
                        <img src={carousel} height="100%" width="100%" alt="carousel item"/>
                        </div>
                    </div>
        </div>
    )
}